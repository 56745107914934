import React from "react"
import tw from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"
import { PrimaryButton as PrimaryButtonBase } from "./misc/Buttons.jsx"

const Container = tw.div`relative h-full`
const Column = tw.div`max-w-screen-md mx-auto py-8 lg:py-12`
const TextContent = tw.div` text-center md:text-left`

const Heading = tw(
  SectionHeading
)`font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-center leading-tight`

const Form = tw.form`text-sm flex flex-col w-full mx-auto md:mx-0 `
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Select = tw.select`mt-6 first:mt-0 border-b-2 py-3  focus:outline-none font-medium transition duration-300 hocus:border-primary-500 `
const Textarea = tw.textarea`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 h-64`
const PrivacyPolicy = tw.a`text-center mt-4 text-base`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-4 text-lg`
const Tel = tw.a`text-center mt-8 text-xl inline-block`
const Information = tw.div`text-center mt-4 text-base`

export default () => {
  return (
    <Container>
      <Column>
        <TextContent>
          <Heading>お問い合わせ</Heading>
          <Form
            name="contact"
            action="/thank-you/"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <Input type="hidden" name="form-name" value="contact" />
            <Input type="hidden" name="bot-field" />
            <Input
              type="text"
              name="subject"
              placeholder=" 会社名(必須)"
              required
            />
            <Input
              type="text"
              name="name"
              placeholder=" お名前(必須)"
              required
            />
            <Input
              type="email"
              name="email"
              placeholder=" 会社メールアドレス(必須)"
              required
            />
            <Select name="category" required>
              <option value="" disabled selected>
                お問い合わせ種別を選択してください(必須)
              </option>
              <option value="サクッとAI">サクッとAI</option>
              <option value="サクッとクラウド">サクッとクラウド</option>
              <option value="資料請求">資料請求</option>
              <option value="取材･執筆のご依頼">取材･執筆のご依頼</option>
              <option value="その他">その他</option>
            </Select>
            <Textarea
              name="message"
              placeholder=" お問い合わせ内容(2000文字以内)"
              maxlength="2000"
            />
            <PrivacyPolicy
              href="https://www.rosso-tokyo.co.jp/contact/consent/"
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報の取り扱いについて
            </PrivacyPolicy>
            <SubmitButton type="submit">
              個人情報の取り扱いに同意し送信
            </SubmitButton>
            <Tel href="tel:0364279037">☎︎ 03-6427-9037</Tel>
            <Information>
              <div>(サクッとAI､サクッとクラウド担当宛)</div>
              <div>営業時間平⽇9:30~18:30 (⼟⽇祝休み)</div>
            </Information>
          </Form>
        </TextContent>
      </Column>
    </Container>
  )
}
